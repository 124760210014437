import Image from 'next/image'
import { styled } from '@mui/system'
import { motion } from 'framer-motion'
import { Box, Container, Grid } from '@mui/material'
import Link from '../common/Link'
import {
  fadeLeft,
  fadeUp,
  fadeUpDelay,
  fade
} from '../../src/animationVariants'
import ConsoleAltis from '../../public/images/pre-order-bg.png'

const GetButton = styled(Link)(({ theme }) => ({
  color: '#fff',
  borderColor: '#219CE1',
  backgroundColor: '#219CE1',
  width: '100%',
  maxWidth: '264px',
  padding: '16px 46px',
  borderRadius: 38,
  fontSize: '1.125rem',
  lineHeight: '1.38889',
  display: 'inline-block',
  textAlign: 'center',
  textDecoration: 'none',
  fontWeight: 900,
  '&:hover': {
    backgroundColor: '#047abc'
  }
}))

const PictureWrap = styled(Box)(({ theme }) => ({
  width: '55%',
  maxHeight: '100% !important',
  position: 'absolute',
  right: 0,
  top: 'auto',
  bottom: 0,
  zIndex: 1,
  '& img': {
    maxHeight: '100% !important',
    minHeight: '100% !important',
    right: 0,
    bottom: '0 !important'
  }
}))

export default function PreOrderToday() {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          background:
            'linear-gradient(239.53deg, #090758 2.98%, #700396 103.15%)',
          borderRadius: 10,
          color: '#fff',
          p: { xs: '65px 25px 120px', md: '48px 60px 55px' },
          mb: 8,
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Grid container spacing={2} sx={{ position: 'relative', zIndex: 100 }}>
          <Grid item xs={12} md={5}>
            <motion.div
              variants={fadeLeft}
              initial={{ opacity: 0, x: 50 }}
              whileInView="onscreen"
              viewport={{ once: false }}
            >
              <Box
                component="h3"
                sx={{
                  p: 0,
                  m: '0 0 24px',
                  fontWeight: 900,
                  fontSize: '1.5rem',
                  lineHeight: '1.25'
                }}
              >
                Reserve My Launch Invite
              </Box>
            </motion.div>
            <motion.div
              variants={fadeUp}
              initial={{ opacity: 0, y: 100 }}
              whileInView="onscreen"
              viewport={{ once: false }}
            >
              <Box sx={{ fontWeight: 700, maxWidth: 500, mb: 5 }}>
                Altis uses AI to measure, track, and evaluate your exercise
                performance and create a personalized path that adapts to you.
              </Box>
            </motion.div>
            <motion.div
              variants={fadeUpDelay}
              initial={{ opacity: 0, y: 100 }}
              whileInView="onscreen"
              viewport={{ once: false }}
            >
              <GetButton href="https://www.indiegogo.com/projects/ai-personal-trainer-powered-by-movementos/">
                Shop Altis
              </GetButton>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={7} />
        </Grid>
        <PictureWrap>
          <motion.div
            variants={fade}
            initial={{ opacity: 0 }}
            whileInView="onscreen"
            viewport={{ once: false }}
            animate={{
              position: 'relative',
              display: 'block',
              width: '100%',
              maxHeight: '100%',
              right: 0,
              bottom: 0,
              transitionEnd: {
                display: 'block'
              }
            }}
          >
            <Image
              alt="Altis"
              src={ConsoleAltis}
              objectFit="contain"
              layout="responsive"
              quality={100}
            />
          </motion.div>
        </PictureWrap>
      </Box>
    </Container>
  )
}
