import { useRef } from 'react'
import Head from 'next/head'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import { Box } from '@mui/material'
import VideoWrap from '../components/home/VideoWrap'
import SignUpWrap from '../components/home/SignUpWrap'
import FeaturesWrap from '../components/common/FeaturesWrap'
import AIPersonalTrainer from '../components/home/AIPersonalTrainer'
import PreOrderToday from '../components/home/PreOrderToday'
import InteractiveFormCoaching from '../components/home/InteractiveFormCoaching'
import HyperPersonalInstruction from '../components/home/HyperPersonalInstruction'
import SleekCompactConsole from '../components/home/SleekCompactConsole'
import MetaHome from '../components/meta/MetaHome'
import { useInView } from 'react-intersection-observer'

export default function Home() {
  const { ref, inView } = useInView()
  const interactiveFormCoachingRef = useRef(null)

  return (
    <>
      <MetaHome />
      <Header isCTAButtonVisible={inView} />
      <Box component='main' sx={{ height: '100%' }}>
        <VideoWrap
          interactiveFormCoachingRef={interactiveFormCoachingRef}
          CTAButtonRef={ref}
        />
        <InteractiveFormCoaching
          interactiveFormCoachingRef={interactiveFormCoachingRef}
        />
        <HyperPersonalInstruction />
        <SleekCompactConsole />
        <FeaturesWrap />
        <PreOrderToday />
        <SignUpWrap />
      </Box>
      <Footer />
    </>
  )
}
